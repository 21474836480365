<template>

<div :class="studentFlag?'css1':'css2'">
  <!-- {{studentFlag}} -->
  <el-main class="tc-answer">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <div class="answer-header layout" :class="studentFlag?'header-box':''">
          <div class="logo">
            <img :src="imgUrl" alt />
          </div>
          <div class="nav_1">
            <ul>
              <!-- <li @click="goHome">
                <span
                  :class="
                    $route.path.split('/')[1] == 'answer_home'
                      ? 'hear_active'
                      : ''
                  "
                  >首页</span
                >
              </li> -->
              <!-- <li>二维码</li>
              <li>帮助</li> -->
            </ul>
          </div>
          <div class="nav_2">
            <ul>
              <!-- <li @click="goHome">
                <span
                  :class="
                    $route.path.split('/')[1] == 'answer_home'
                      ? 'hear_active'
                      : ''
                  "
                  >首页</span
                >
              </li> -->
              <li></li>
              <li @click="goMeaList">
                <span
                  :class="
                    $route.path.split('/')[$route.path.split('/').length - 1] ==
                    'answer_list'
                      ? 'hear_active'
                      : ''
                  "
                  >测评管理</span
                >
              </li>
              <li @click="goMea" v-show="!studentFlag">
                <span
                  :class="
                    $route.path.split('/')[$route.path.split('/').length - 1] ==
                    'answerUserMeasure'
                      ? 'hear_active'
                      : ''
                  "
                  
                  >报告管理</span
                >
              </li>
              <li @click="goQues" v-if="!studentFlag">
                <span
                  :class="
                    $route.path.split('/')[$route.path.split('/').length - 1] ==
                    'answer_ques'
                      ? 'hear_active'
                      : ''
                  "
                  
                  >问卷管理</span
                >
              </li>
              <li v-if="studentFlag" @click="goOne" >
                <span                   
                :class="
                    $route.path.split('/')[$route.path.split('/').length - 1] ==
                    'answerUserMeasureertong'
                      ? 'hear_active'
                      : ''
                  ">

                  个人中心
                </span>
              </li>
            </ul>
          </div>
          <div class="userinfo">
            <ul>
              <li>
                欢迎您
                <span class="highlight">{{ userInfo.real_name }}</span>
              </li>

              <li>
                <el-dropdown trigger="click">
                  <div>
                    <img
                      :src="userInfo.haderurl"
                      width="40px"
                      height="40px"
                      alt
                      v-if="userInfo.haderurl != ''"
                    />
                    <img
                      src="static/img/regtx.png"
                      width="40px"
                      height="40px"
                      alt
                      v-else
                    />
                    <span class="el-dropdown-link">
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                  </div>

                  <el-dropdown-menu slot="dropdown">
                    <router-link to="/answerUserInfo" v-if="!studentFlag">
                      <el-dropdown-item>个人中心</el-dropdown-item>
                    </router-link>
                    <!-- <router-link to="/answer_content/userVideo">
                      <el-dropdown-item>视频问诊</el-dropdown-item>
                    </router-link> -->
<!--                    <router-link to="/answer_content/information">-->
<!--                      <el-dropdown-item>晤谈内容</el-dropdown-item>-->
<!--                    </router-link>-->
<!--                    <router-link to="/answer_content/game">-->
<!--                      <el-dropdown-item>游戏</el-dropdown-item>-->
<!--                    </router-link>-->
<!--                    <router-link to="/answer_content/music">-->
<!--                      <el-dropdown-item>音乐</el-dropdown-item>-->
<!--                    </router-link>-->
                    <!-- <div>
                      <el-dropdown-item>返回管理</el-dropdown-item>
                    </div>-->
                    <el-dropdown-item @click.native="exit"
                      >退出</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  v-if="isAdmin"
                  v-show="!studentFlag"
                  @click="showSwitchoverBox"
                  >返回管理端</el-button
                >
              </li>
              <!-- <li class="hint">
                <img src="../../assets/images/铃铛.png" alt width="30px" />
                <i>10</i>
              </li>-->
            </ul>
          </div>
        </div>
      </el-header>
      <!-- 主题 -->
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive == true" />
        </keep-alive>
        <router-view v-if="$route.meta.keepAlive == false" />
        <el-dialog
          :close-on-click-modal="false"
          title="管理员认证"
          :visible.sync="switchoverBox"
          width="30%"
          center
        >
          <div class="switchoverBox">
            <span>管理员密码：</span>
            <el-input
              type="password"
              v-model="inputPassword"
              placeholder="请输入管理员密码"
              @keyup.enter.native="switchover_user"
            ></el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="switchoverBox = false">取 消</el-button>
            <el-button type="primary" @click="switchover_user">确 定</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </el-main>
</div>
</template>

<script>
import axios from "axios";
// import { nextTick } from "q";
import { submit, getuserinfo, switchoverUser } from "@/api/answer.js";
import { loginOut } from "@/api/login.js";
import { getListConfig } from "@/api/comm.js";
export default {
  data() {
    return {
      switchoverBox: false,
      inputPassword: "",
      ischeck: "",
      resource: "",
      picked: "",
      measureInfo: "",
      questionsList: [],
      index: 0,
      answer: [],
      // 用户信息
      userInfo: {},
      imgUrl: "",
      isAdmin: false,
      studentFlag:false,
    };
  },
  created() {
    let student = localStorage.getItem("student");
    if(student==1){
      this.studentFlag=true;
    }
    // this.loadanswer();
    // 获取用户信息
    // //console.log("00");
    getuserinfo().then((res) => {
      // //console.log(res);
      if (res.code == 400200) {
        this.userInfo = res.data;
        localStorage.setItem("user_id", this.userInfo.id);
        // localStorage.setItem("measurement_num", this.userInfo.measurement_num);
        localStorage.setItem("userName", this.userInfo.real_name);
        // localStorage.setItem(
        //   "measure",
        //   JSON.stringify({
        //     measurement_num: this.userInfo.measurement_num,
        //     begin_time: this.userInfo.begin_time,
        //     end_time: this.userInfo.end_time
        //   })
        // );
      }
    });
    let config = JSON.parse(localStorage.getItem("config"));
    if (config == null) {
      getListConfig().then((res) => {
        if (res.code == 400200) {
          localStorage.setItem("config", JSON.stringify(res.data));
          this.imgUrl = res.data.logo;
        }
      });
    } else {
      this.imgUrl = config.logo;
    }
    // 判断是否是从管理员切换过来
    if (localStorage.getItem("switchover")) {
      this.isAdmin = true;
    }
  },
  mounted() {
    // L2Dwidget.init({
    //   display: {
    //     superSample: 2,
    //     width: 200,
    //     height: 400,
    //     position: "right",
    //     hOffset: 0,
    //     vOffset: 0
    //   }
    // });
  },
  methods: {
    // 退出
    exit() {
      this.$confirm("是否退出系统", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginOut().then((res) => {
            // //console.log(res);
            if (res.code === 400200) {
              this.$message({
                showClose: true,
                duration: 1500,
                type: "success",
                message: "欢迎再次光临!",
              });
            }
            this.socketApi.websock.close();
            localStorage.clear();
            if (this._isMobile()) {
              // type:退出类型，重新获取长链接ID
              this.$router.push({ path: "/userLogin?type=out" });
            } else {
              this.$router.push({ path: "/login?type=out" });
            }
          });
        })
        .catch(() => {});
    },
    // 去个人中心
    goUserInfo() {
      this.$router.push(`/answerUserInfo`);
    },
    goMea() {

      this.$router.push(`/answer_content/answerUserMeasure`);

    },
    goMeaList() {
      this.$router.push(`/answer_content/answer_list`);
    },
    goQues() {

       this.$router.push(`/answer_content/answer_ques`);
    },
    goOne(){
        this.$router.push(`/answer_content/answerUserMeasureertong`);

    },
    goHome() {
      this.$router.push(`/answer_home`);
    },
    // 判断手机
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 切换到用户测试页面
    switchover_user() {
      let data = { id: localStorage.getItem("switchover") };
      let config = JSON.parse(localStorage.getItem("config"));
      if (config.switchover == 1) {
        data.password = this.inputPassword;
      }
      switchoverUser(data).then((res) => {
        if (res.code == 400200) {
          // localStorage.clear(); //清除用户信息
          this.$router.push(`/user_evaluate`);
        } else {
          // this.$message({
          //   showClose: true,
          //   duration: 1500,
          //   message: res.msg,
          //   type: "error"
          // });
        }
      });
    },
    showSwitchoverBox() {
      let config = JSON.parse(localStorage.getItem("config"));
      if (config.switchover == 1) {
        this.switchoverBox = true;
      } else {
        this.switchover_user();
      }
    },
  },
};
</script>

<style lang="less">
.css2{
  .el-main {
    padding: 0px !important;
    background-image: linear-gradient(
      to bottom,
      rgb(255, 255, 255),
      rgb(169, 206, 206),
      rgb(255, 255, 255)
    ) !important;
    box-shadow: black 0px 2px 15px;
  }
  .tc-answer {
    height: 100%;
    .layout {
      max-width: 1300px;
      min-width: 320px;
      margin: 0 auto;
    }
    a {
      color: black;
      text-decoration: none;
    }
    .el-container {
      height: 100%;
      .el-header {
        height: 100px !important;
        background: rgb(197, 239, 241);
        li {
          list-style: none;
        }
        .answer-header {
          width: 100%;
          height: 100%;
          display: flex;
          .logo {
            flex: 1.5;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            padding: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .nav_1 {
            flex: 1.5;
            ul {
              display: flex;
              li {
                line-height: 100px;
                width: 33.33%;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                color: #1b484b;
              }
            }
          }
          .nav_2 {
            flex: 2;
            ul {
              display: flex;
              li {
                line-height: 100px;
                width: 33.33%;
                text-align: center;
                font-size: 18px;
                color: #1b484b;
                font-weight: 700;
                &:nth-child(1) {
                  display: none;
                }
                img {
                  width: 20px;
                }
              }
            }
          }
          .userinfo {
            flex: 4;
            ul {
              // padding-left: 60px;
              margin-left: 60px;
              display: flex;
              line-height: 100px;
              li {
                text-align: center;
                padding-right: 10px;
                img {
                  vertical-align: middle;
                  border-radius: 50%;
                }
                &:nth-child(4) {
                  position: relative;
                  i {
                    background: red;
                    color: white;
                    border-radius: 9px;
                    position: absolute;
                    height: 15px;
                    display: inline-block;
                    line-height: 15px;
                    top: 33px;
                    right: 9px;
                    font-size: 10px;
                  }
                }
                .el-button--primary {
                  color: rgb(5, 140, 202);
                  background: rgb(252, 252, 252);
                  border-color: #b3d8ff;
                  &:hover {
                    color: rgb(255, 255, 255);
                    background: rgb(45, 123, 212);
                    border-color: #b3d8ff;
                  }
                }
              }
              .out {
                margin-left: 20px;
                font-size: 20px;
                color: rgb(9, 100, 204);
                cursor: pointer;
              }
            }
            .highlight {
              color: rgb(9, 100, 204);
            }
          }
          .hear_active {
            color: rgb(9, 100, 204);
            transition: all 0.25s;
          }
        }
      }
      .el-main {
        height: 100%;
        background: #cccccc;
      }
    }
    .el-dropdown {
      height: 70px;
    }
    .switchoverBox {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .el-input {
        width: 250px;
      }
    }
  }
}
@media screen and (max-width: 812px) {
  .el-main {
    padding: 0px;
  }
  .tc-answer {
    height: 100%;
    .layout {
      max-width: 640px;
      min-width: 320px;
    }
    .el-container {
      height: 100%;
      .el-header {
        padding: 0px;
        height: 60px !important;
        li {
          list-style: none;
        }
        .answer-header {
          width: 100%;
          height: 100%;
          display: flex;
          .logo {
            display: none;
            flex: 1;
            img {
              width: 100px;
            }
          }
          .nav_1 {
            display: none;
            flex: 1;
            ul {
              display: flex;
              li {
                line-height: 60px;
                width: 33.33%;
                text-align: center;
                font-size: 16px;
              }
            }
          }
          .nav_2 {
            // display: none;
            flex: 5;
            ul {
              display: flex;
              justify-content: space-between;
              li {
                line-height: 60px;
                width: 28%;
                text-align: center;
                font-size: 14px;
                &:nth-child(1) {
                  display: block;
                }
                img {
                  width: 20px;
                }
              }
            }
          }
          .userinfo {
            // display: none;
            flex: 1.5;
            ul {
              margin-left: 0px;
              padding-left: 0px;
              display: flex;
              justify-content: flex-end;
              line-height: 60px;

              li {
                text-align: center;
                padding-right: 5px;
                img {
                  vertical-align: middle;
                  border-radius: 50%;
                }
                &:nth-child(1) {
                  display: none;
                }
                &:nth-child(2) {
                }
                &:nth-child(3) {
                  display: none;
                }
                &:nth-child(4) {
                  display: none;
                  position: relative;
                  i {
                    background: red;
                    color: white;
                    border-radius: 9px;
                    position: absolute;
                    height: 15px;
                    display: inline-block;
                    line-height: 15px;
                    top: 33px;
                    right: 9px;
                    font-size: 10px;
                  }
                }
              }
            }

            .highlight {
              display: none;
              color: rgb(9, 100, 204);
            }
            .out {
              display: none;
            }
          }
        }
      }
      .el-main {
        height: 100vh;
        background: #cccccc;
      }
    }

    .el-dropdown {
      height: 50px;
    }
  }
  .el-message-box__wrapper {
    .el-message-box {
      width: 70%;
    }
  }
}
@media screen and (max-width: 320px) {
  .el-main {
    padding: 0px;
  }
  .tc-answer {
    height: 100%;
    .layout {
      max-width: 640px;
      min-width: 320px;
    }
    .el-container {
      height: 100%;
      .el-header {
        padding: 0px;
        height: 60px !important;
        li {
          list-style: none;
        }
        .answer-header {
          width: 100%;
          height: 100%;
          display: flex;
          .logo {
            display: none;
            flex: 1;
            img {
              width: 100px;
            }
          }
          .nav_1 {
            display: none;
            flex: 1;
            ul {
              display: flex;
              li {
                line-height: 60px;
                width: 33.33%;
                text-align: center;
                font-size: 16px;
              }
            }
          }
          .nav_2 {
            // display: none;
            flex: 5;
            ul {
              display: flex;
              justify-content: space-between;
              li {
                line-height: 60px;
                width: 28%;
                text-align: center;
                font-size: 14px;
                color: #1b484b;
                &:nth-child(1) {
                  display: block;
                }
                img {
                  width: 20px;
                }
              }
            }
          }
          .userinfo {
            // display: none;
            flex: 1.5;
            ul {
              margin-left: 0px;
              padding-left: 0px;
              display: flex;
              justify-content: center;
              line-height: 60px;

              li {
                text-align: center;
                padding-right: 5px;
                img {
                  vertical-align: middle;
                  border-radius: 50%;
                }
                &:nth-child(1) {
                  display: none;
                }
                &:nth-child(2) {
                }
                &:nth-child(3) {
                  display: none;
                }
                &:nth-child(4) {
                  display: none;
                  position: relative;
                  i {
                    background: red;
                    color: white;
                    border-radius: 9px;
                    position: absolute;
                    height: 15px;
                    display: inline-block;
                    line-height: 15px;
                    top: 33px;
                    right: 9px;
                    font-size: 10px;
                  }
                }
              }
            }

            .highlight {
              display: none;
              color: rgb(9, 100, 204);
            }
            .out {
              display: none;
            }
          }
        }
      }
      .el-main {
        height: 100vh;
        background: #cccccc;
      }
    }

    .el-dropdown {
      height: 50px;
    }
  }
  .el-message-box__wrapper {
    .el-message-box {
      width: 70%;
    }
  }
}
</style>

<style scoped lang="less">
@media  (min-width: 750px) and (max-width: 2866px) {
.css1{
  .el-main {
    padding: 0px;
  }
  .tc-answer {
    height: 100%;
    a {
      color: black;
      text-decoration: none;
    }
    .el-container {
      height: 100%;
      .el-header {
        height: 100px !important;
        // background: rgb(197, 239, 241);
        border-bottom: 2px  rgb(237, 235, 235) solid;

        li {
          list-style: none;
        }
        .answer-header {
          width: 100%;
          height: 100%;
          display: flex;
          .logo {
            flex: 1.5;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            padding: 20px;
            margin-left: 100px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .nav_1 {
            flex: 1.5;
            ul {
              display: flex;
              li {
                line-height: 100px;
                width: 33.33%;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                color: #1b484b;
              }
            }
          }
          .nav_2 {
            flex: 6;
            ul {
              display: flex;
              li {
                line-height: 100px;
                width: 33.33%;
                text-align: center;
                font-size: 18px;
                color: #1b484b;
                font-weight: 700;
                &:nth-child(1) {
                  display: none;
                }
                img {
                  width: 20px;
                }
              }
            }
          }
          .userinfo {
            flex: 4;
            margin-left: 100px;
            ul {
              // padding-left: 60px;
              margin-left: 60px;
              display: flex;
              line-height: 100px;
              li {
                text-align: center;
                padding-right: 10px;
                img {
                  vertical-align: middle;
                  border-radius: 50%;
                }
                &:nth-child(4) {
                  position: relative;
                  i {
                    background: red;
                    color: white;
                    border-radius: 9px;
                    position: absolute;
                    height: 15px;
                    display: inline-block;
                    line-height: 15px;
                    top: 33px;
                    right: 9px;
                    font-size: 10px;
                  }
                }
                .el-button--primary {
                  color: rgb(5, 140, 202);
                  background: rgb(252, 252, 252);
                  border-color: #b3d8ff;
                  &:hover {
                    color: rgb(255, 255, 255);
                    background: rgb(45, 123, 212);
                    border-color: #b3d8ff;
                  }
                }
              }
              .out {
                margin-left: 20px;
                font-size: 20px;
                color: rgb(9, 100, 204);
                cursor: pointer;
              }
            }
            .highlight {
              color: rgb(9, 100, 204);
            }
          }
          .hear_active {
            color: rgb(9, 100, 204);
            transition: all 0.25s;
          }
        }
      }
      .el-main {
        height: calc(100vh - 100px);
        // background: #1b484b;
        background: url("../../assets/images/children_one.png") no-repeat center center;
        background-size: cover;
      }
    }
    .el-dropdown {
      height: 70px;
    }
    .switchoverBox {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .el-input {
        width: 250px;
      }
    }
  }
}
}
</style>
